import React, { useState, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios, { AxiosInstance } from 'axios';
import { useWebSocket } from './hooks/useWebSocket';
import { Sidebar } from './components/Sidebar';
import { Navbar } from './components/Navbar';
import ModulePanel from './components/ModulePanel';
import { StatisticsPanel } from './components/StatisticsPanel';
import { SettingsPanel } from './components/SettingsPanel';
import { ChangelogPanel } from './components/ChangeLogPanel';
import { DownloadPanel } from './components/DownloadPanel';
import { useNavigate } from 'react-router-dom';

interface UserProfile {
  username: string;
  displayname?: string;
  isAdmin: boolean;
  subscriptionActive: boolean;
  subscriptionExpiry?: string;
  invitedBy?: string;
  createdAt: string;
}

interface Statistics {
  userStats: {
    totalUsers: number;
    activeUsers: number;
    bannedUsers: number;
  };
  downloadStats: {
    totalDownloads: number;
    downloadsPerVersion: Array<{ _id: string; downloads: number }>;
    recentDownloads: Array<any>;
    downloadTrend: Array<{ _id: string; count: number }>;
  };
}

const api: AxiosInstance = axios.create({
  baseURL: 'https://api.wax.rest/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const Star: React.FC<{ index: number }> = ({ index }) => {
  const style = useMemo(() => ({
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    width: `${Math.random() * 3 + 1}px`,
    height: `${Math.random() * 3 + 1}px`,
  }), []);

  return (
    <motion.div
      className="absolute rounded-full bg-yellow-400"
      style={style}
      animate={{
        scale: [1, 1.5, 1],
        opacity: [0.7, 1, 0.7],
        y: [0, Math.random() * 20 - 10],
      }}
      transition={{
        duration: Math.random() * 3 + 2,
        repeat: Infinity,
        repeatType: 'reverse',
      }}
    />
  );
};

const StarryBackground: React.FC = () => {
  const stars = useMemo(() => {
    return [...Array(50)].map((_, i) => <Star key={i} index={i} />);
  }, []);

  return <>{stars}</>;
};

export const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('modules');
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [statistics, setStatistics] = useState<Statistics | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { modules, setModules, status, sendMessage, loading: wsLoading } = useWebSocket('ws://localhost:8080');
  const navigate = useNavigate();

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const memoizedStarryBackground = useMemo(() => <StarryBackground />, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get('https://api.wax.rest/api/auth/info', {
          headers: { Authorization: `Bearer ${token}` }
        });

        setUserProfile(response.data);
      } catch (err) {
        console.error('Failed to fetch user profile:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-black text-white overflow-hidden">
      <Navbar toggleSidebar={toggleSidebar} connectionStatus={status} userProfile={userProfile} />
      <div className="flex flex-1 overflow-hidden pt-16">
        <Sidebar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <main className="flex-1 overflow-auto p-4 sm:p-6 lg:ml-64">
          <div className="absolute inset-0 z-0">
            <div className="absolute inset-0 bg-gradient-to-b from-yellow-400/20 to-black opacity-50"></div>
            {memoizedStarryBackground}
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="relative z-10"
            >
              {activeTab === 'modules' && (
                <ModulePanel 
                  modules={modules} 
                  setModules={setModules}
                  sendMessage={sendMessage} 
                  loading={wsLoading} 
                />
              )}              
              {/* {activeTab === 'statistics' && statistics && userProfile && (
                <StatisticsPanel statistics={statistics} userProfile={userProfile} />
              )} */}
              {activeTab === 'settings' && userProfile && (
                <SettingsPanel userProfile={userProfile} />
              )}
              {activeTab === 'download' && <DownloadPanel />}
              {activeTab === 'changelog' && <ChangelogPanel />}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;