import React, { useState, useMemo, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Switch } from './ui/switch'
import { Slider } from './ui/slider'
import { Input } from './ui/input'
import { Button } from './ui/button'
import { ScrollArea } from './ui/scroll-area'
import { Search, Settings, Keyboard, ChevronDown, Star, Sparkles, Box, HelpCircle } from 'lucide-react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs'
import { cn } from '../lib/utils'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip"

interface Setting {
  name: string
  value: string | number | boolean
  type: string
  default: string | number | boolean
  min?: number
  max?: number
  options?: string[]
}

interface Page {
  name: string
  settings: string[]
}

interface Module {
  name: string
  enabled: boolean
  category: string
  description: string
  settings: Record<string, Setting>
  pages: Page[]
  keybind: number
}

interface ModuleListProps {
  modules: Module[]
  setModules: React.Dispatch<React.SetStateAction<Module[]>>
  sendMessage: (message: string) => void
  loading: boolean
}

type Category = 'ALL' | 'COMBAT' | 'MOVEMENT' | 'UTILITY' | 'VISUAL' | 'MISCELLANEOUS'
const categories = ['ALL', 'COMBAT', 'MOVEMENT', 'UTILITY', 'VISUAL', 'MISCELLANEOUS'] as const

const keyCodeToChar = (keyCode: number): string => {
  if (keyCode >= 65 && keyCode <= 90) {
    return String.fromCharCode(keyCode)
  }
  return keyCode === -1 ? 'NONE' : ''
}

const ModuleCard: React.FC<{ 
  module: Module
  isActive: boolean
  onClick: () => void
  toggleModule: () => void
  updateSetting: (name: string, value: string | number | boolean) => void
  handleKeybindChange: (newKeybind: number) => void 
}> = ({ module, isActive, onClick, toggleModule, updateSetting, handleKeybindChange }) => {
  const [activePage, setActivePage] = useState(module.pages[0]?.name || '')
  const [isListening, setIsListening] = useState(false)
  const hasPages = module.pages.length > 0

  const handleCardClick = (e: React.MouseEvent) => {
    const clickedElement = e.target as HTMLElement
    const isClickableArea = clickedElement.closest('.module-header')
    if (isClickableArea && hasPages) {
      onClick()
    }
  }

  const handleKeybindChangeCard = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    setIsListening(true)
    const keyInput = document.createElement('input')
    keyInput.style.position = 'fixed'
    keyInput.style.opacity = '0'
    document.body.appendChild(keyInput)
    keyInput.focus()

    const handleKeyDown = (e: KeyboardEvent) => {
      e.preventDefault()
      const newKeybind = e.keyCode >= 65 && e.keyCode <= 90 ? e.keyCode : (e.key === 'Backspace' || e.key === 'Escape' ? -1 : module.keybind)
      handleKeybindChange(newKeybind)
      document.body.removeChild(keyInput)
      document.removeEventListener('keydown', handleKeyDown)
      setIsListening(false)
    }

    document.addEventListener('keydown', handleKeyDown, { once: true })
  }, [module, handleKeybindChange])

  return (
    <motion.div
      layout
      onClick={handleCardClick}
      className={cn(
        "border rounded-lg transition-all duration-300 ease-in-out overflow-hidden",
        "bg-gradient-to-br from-black/50 to-black/30",
        module.enabled ? "border-yellow-400 shadow-md shadow-yellow-400/20" : "border-yellow-400/20 hover:border-yellow-400/50",
        hasPages ? "cursor-pointer" : "cursor-default"
      )}
      initial={false}
      animate={{ height: isActive && hasPages ? 'auto' : 'auto' }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="p-3 flex items-center justify-between module-header">
        <div className="flex items-center space-x-3">
          <motion.div
            className="relative w-8 h-8 bg-yellow-400/20 rounded-full flex items-center justify-center"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Box className="w-4 h-4 text-yellow-400" />
          </motion.div>
          <div className="flex items-center space-x-2">
            <h3 className="font-semibold text-base text-yellow-400">{module.name}</h3>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <HelpCircle className="w-4 h-4 text-yellow-400/60" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{module.description}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {hasPages && (
              <ChevronDown className={`w-4 h-4 text-yellow-400/60 transition-transform ${isActive ? 'rotate-180' : ''}`} />
            )}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <div
            className="flex items-center space-x-1 bg-black/30 rounded px-1.5 py-0.5 cursor-pointer"
            onClick={handleKeybindChangeCard}
          >
            <Keyboard className="w-3 h-3 text-yellow-400" />
            <span className="text-[10px] text-yellow-400 min-w-[20px] text-center">
              {isListening ? '...' : keyCodeToChar(module.keybind)}
            </span>
          </div>
          <Switch
            checked={module.enabled}
            onCheckedChange={(checked) => {
              toggleModule()
              if (!isActive && checked) onClick()
            }}
            className="data-[state=checked]:bg-yellow-400"
          />
        </div>
      </div>
      <AnimatePresence>
        {isActive && hasPages && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="px-4 pb-4"
          >
            <Tabs value={activePage} onValueChange={setActivePage}>
              <TabsList className="w-full bg-black/40 rounded p-1 mb-3">
                {module.pages.map((page) => (
                  <TabsTrigger
                    key={page.name}
                    value={page.name}
                    className="flex-1 data-[state=active]:bg-yellow-400 data-[state=active]:text-black rounded transition-all duration-200 text-xs py-1"
                  >
                    {page.name}
                  </TabsTrigger>
                ))}
              </TabsList>
              {module.pages.map((page) => (
                <TabsContent key={page.name} value={page.name} className="space-y-3">
                  {page.settings.map((settingName) => {
                    const setting = module.settings[settingName]
                    return (
                      <motion.div
                        key={settingName}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.2 }}
                        className="flex items-center justify-between"
                      >
                        <span className="text-xs font-medium text-yellow-100">{settingName}</span>
                        <div className="w-1/2" onClick={(e) => e.stopPropagation()}>
                          <SettingControl
                            setting={setting}
                            updateSetting={(name, value) => updateSetting(name, value)}
                          />
                        </div>
                      </motion.div>
                    )
                  })}
                </TabsContent>
              ))}
            </Tabs>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

const SettingControl: React.FC<{ setting: Setting; updateSetting: (name: string, value: string | number | boolean) => void }> = ({ setting, updateSetting }) => {
  const handleFloatChange = (value: number[]) => {
    const newValue = Number(value[0].toFixed(2))
    updateSetting(setting.name, newValue)
  }

  switch (setting.type) {
    case 'Boolean':
      return (
        <div className="flex justify-end">
          <Switch
            checked={setting.value as boolean}
            onCheckedChange={(checked) => updateSetting(setting.name, checked)}
            className="data-[state=checked]:bg-yellow-400"
          />
        </div>
      )
    case 'Float':
      return (
        <div className="flex items-center space-x-2 w-full flex-row-reverse">
          <Slider
            min={setting.min || 0}
            max={setting.max || 100}
            step={0.01}
            value={[setting.value as number]}
            onValueChange={handleFloatChange}
            className="flex-grow"
          />
          <span className="w-12 text-left text-xs text-yellow-400/80">{(setting.value as number).toFixed(2)}</span>
        </div>
      )
    case 'Integer':
      return (
        <div className="flex items-center space-x-2 w-full flex-row-reverse">
          <Slider
            min={setting.min || 0}
            max={setting.max || 100}
            step={1}
            value={[setting.value as number]}
            onValueChange={(value) => updateSetting(setting.name, value[0])}
            className="flex-grow"
          />
          <span className="w-8 text-left text-xs text-yellow-400/80">{setting.value}</span>
        </div>
      )
    case 'String':
      return (
        <Input
          value={setting.value as string}
          onChange={(e) => updateSetting(setting.name, e.target.value)}
          className="w-full text-xs bg-black/30 border-yellow-400/30 text-yellow-400 rounded"
        />
      )
    default:
      if ('options' in setting) {
        return (
          <Select
            value={setting.value as string}
            onValueChange={(value) => updateSetting(setting.name, value)}
          >
            <SelectTrigger className="w-full text-xs bg-black/30 border-yellow-400/30 text-yellow-400 rounded">
              <SelectValue placeholder="Select an option" />
            </SelectTrigger>
            <SelectContent className="bg-black/90 border-yellow-400/30">
              {(setting.options as string[]).map((option) => (
                <SelectItem
                  key={option}
                  value={option}
                  className="text-yellow-400 hover:bg-yellow-400/20"
                >
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )
      }
      return null
  }
}

export default function ModulePanel({ modules: initialModules, setModules, sendMessage, loading }: ModuleListProps) {
  const [activeModules, setActiveModules] = useState<string[]>([])
  const [filter, setFilter] = useState<Category>('ALL')
  const [searchTerm, setSearchTerm] = useState('')
  const [sortAlphabetically] = useState(true)
  const [sortDirection] = useState<'asc'>('asc')

  const filteredModules = useMemo(() => {
    let filtered = filter === 'ALL' ? initialModules : initialModules.filter(m => m.category === filter)
    if (searchTerm) {
      filtered = filtered.filter(m => m.name.toLowerCase().includes(searchTerm.toLowerCase()))
    }
    if (sortAlphabetically) {
      filtered = [...filtered].sort((a, b) => {
        const comparison = a.name.localeCompare(b.name)
        return sortDirection === 'asc' ? comparison : -comparison
      })
    }
    return filtered
  }, [filter, initialModules, searchTerm, sortAlphabetically, sortDirection])

  const updateModule = useCallback((updatedModule: Module) => {
    setModules(prevModules =>
      prevModules.map(module =>
        module.name === updatedModule.name ? updatedModule : module
      )
    )
  }, [setModules])

  const toggleModule = useCallback((module: Module) => {
    const enabled = !module.enabled
    sendMessage(`${enabled ? 'ENABLE' : 'DISABLE'}_MODULE ${module.name}`)
    updateModule({ ...module, enabled })
  }, [sendMessage, updateModule])

  const updateSetting = useCallback((module: Module, settingName: string, value: string | number | boolean) => {
    sendMessage(`SET_SETTING ${module.name} "${settingName}" "${value}"`)
    updateModule({
      ...module,
      settings: {
        ...module.settings,
        [settingName]: { ...module.settings[settingName], value }
      }
    })
  }, [sendMessage, updateModule])

  const handleKeybindChange = useCallback((module: Module, newKeybind: number) => {
    sendMessage(`SET_KEYBIND ${module.name} ${newKeybind}`)
    updateModule({ ...module, keybind: newKeybind })

    setModules(prevModules => 
      prevModules.map(m => 
        m.keybind === newKeybind && m.name !== module.name
          ? { ...m, keybind: newKeybind }
          : m
      )
    )
  }, [sendMessage, updateModule, setModules])

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <motion.div
          animate={{ rotate:360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className="w-8 h-8 border-2 border-yellow-400 border-t-transparent rounded-full"
        />
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col text-yellow-100 text-sm p-4 rounded-lg">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 gap-4"
      >
        <h2 className="text-2xl font-bold text-yellow-400 tracking-tight flex items-center gap-2">
          <Sparkles className="h-6 w-6" />
          Modules
        </h2>
        <div className="flex items-center space-x-2 w-full sm:w-auto">
          <Input
            type="text"
            placeholder="Search modules..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full sm:w-48 bg-black/30 border-yellow-400/30 text-yellow-100 placeholder-yellow-400/50 rounded text-xs"
          />
          <Button
            variant="outline"
            onClick={() => setFilter(filter === 'ALL' ? 'COMBAT' : 'ALL')}
            className="border-yellow-400/30 text-yellow-400 hover:bg-yellow-400/20 rounded"
          >
            <Search className="h-4 w-4" />
          </Button>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="flex flex-wrap gap-2 mb-4"
      >
        {categories.map((category, index) => (
          <motion.div
            key={category}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <Button
              variant="outline"
              size="sm"
              onClick={() => setFilter(category)}
              className={cn(
                "text-xs font-medium rounded transition-all duration-200",
                filter === category
                  ? "bg-yellow-400 text-black border-transparent"
                  : "text-yellow-400 border-yellow-400/30 hover:bg-yellow-400/20"
              )}
            >
              {category}
            </Button>
          </motion.div>
        ))}
      </motion.div>
      <ScrollArea className="flex-grow -mx-4 px-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
        >
          {filteredModules.map((module, index) => (
            <motion.div
              key={module.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.05 }}
            >
              <ModuleCard
                module={module}
                isActive={activeModules.includes(module.name)}
                onClick={() => {
                  setActiveModules(prev =>
                    prev.includes(module.name)
                      ? prev.filter(name => name !== module.name)
                      : [...prev, module.name]
                  )
                }}
                toggleModule={() => toggleModule(module)}
                updateSetting={(name, value) => updateSetting(module, name, value)}
                handleKeybindChange={(newKeybind) => handleKeybindChange(module, newKeybind)}
              />
            </motion.div>
          ))}
        </motion.div>
      </ScrollArea>
    </div>
  )
}

