import { useState, useEffect, useCallback } from 'react';

export interface Setting {
  name: string;
  value: string | number | boolean;
  type: 'Boolean' | 'Float' | 'Integer' | 'String' | string;
  default: string | number | boolean;
  min?: number;
  max?: number;
  options?: string[];
}

export interface Page {
  name: string;
  settings: string[];
}

interface Module {
  name: string;
  enabled: boolean;
  category: string;
  description: string;
  settings: Record<string, Setting>;
  pages: Page[];
  keybind: number;
}

type ConnectionStatus = 'connecting' | 'connected' | 'disconnected' | 'error';

export const useWebSocket = (url: string) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [status, setStatus] = useState<ConnectionStatus>('disconnected');
  const [modules, setModules] = useState<Module[]>([]);
  const [loading, setLoading] = useState(true);

  const connect = useCallback(() => {
    const newSocket = new WebSocket(url);

    newSocket.onopen = () => {
      setStatus('connected');
      newSocket.send('GET_ALL_MODULE_DATA');
    };

    newSocket.onclose = () => {
      setStatus('disconnected');
      setTimeout(connect, 5000);
    };

    newSocket.onerror = (error) => {
      setStatus('error');
    };

    newSocket.onmessage = (event) => {
      if (typeof event.data === 'string') {
        if (event.data.startsWith('ALL_MODULE_DATA')) {
          if (event.data.trim().endsWith('}')) {
            const moduleData = parseAllModuleData(event.data);
            setModules(moduleData);
            setLoading(false);
          } else {
            console.error('Received incomplete module data');
          }
        } else if (event.data.startsWith('MODULE_ENABLED') || event.data.startsWith('MODULE_DISABLED')) {
          const [action, moduleName] = event.data.split(' ');
          setModules(prevModules =>
            prevModules.map(module =>
              module.name === moduleName ? { ...module, enabled: action === 'MODULE_ENABLED' } : module
            )
          );
        } else if (event.data.startsWith('SETTING_UPDATED')) {
          const [, moduleName, settingName, newValue] = event.data.split(' ');
          setModules(prevModules =>
            prevModules.map(module =>
              module.name === moduleName
                ? {
                    ...module,
                    settings: {
                      ...module.settings,
                      [settingName]: {
                        ...module.settings[settingName],
                        value: parseSettingValue(module.settings[settingName]?.type, newValue),
                      },
                    },
                  }
                : module
            )
          );
        } else if (event.data.startsWith('KEYBIND_UPDATED')) {
          const [, moduleName, newKeybind] = event.data.split(' ');
          setModules(prevModules =>
            prevModules.map(module =>
              module.name === moduleName ? { ...module, keybind: parseInt(newKeybind, 10) } : module
            )
          );
        } else if (event.data.startsWith('MODULE_TOGGLED')) {
          const [, moduleName, state] = event.data.split(' ');
          setModules(prevModules =>
            prevModules.map(module =>
              module.name === moduleName ? { ...module, enabled: state === 'enabled' } : module
            )
          );
        }
      }
    };

    setSocket(newSocket);
  }, [url]);

  useEffect(() => {
    connect();
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [connect]);

  const sendMessage = useCallback((message: string) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    }
  }, [socket]);

  return { modules, setModules, status, sendMessage, loading };
};

function parseAllModuleData(data: string): Module[] {
  try {
    const moduleDataString = data.replace(/^ALL_MODULE_DATA\s*/, '').trim();
    
    const fixedData = moduleDataString
      .replace(/,\s*([}\]])/g, '$1')
      .replace(/,,/g, ',')
      .replace(/([{,]\s*)(\w+):/g, '$1"$2":')
      .replace(/,\s*([}\]])/g, '$1');

    const parseJSON = (str: string) => {
      return JSON.parse(str, (key, value) => {
        if (typeof value === 'string') {
          try {
            return JSON.parse(value);
          } catch {
            return value;
          }
        }
        return value;
      });
    };

    const moduleData = parseJSON(fixedData);

    return Object.entries(moduleData).map(([name, data]: [string, any]) => {
      try {
        return {
          name,
          enabled: Boolean(data.enabled),
          category: String(data.category),
          description: String(data.description),
          settings: Object.entries(data.settings || {}).reduce((acc, [settingName, settingData]: [string, any]) => {
            try {
              acc[settingName] = {
                name: settingName,
                ...settingData,
                value: parseSettingValue(settingData.type, settingData.value),
                min: settingData.min !== undefined ? parseFloat(String(settingData.min)) : undefined,
                max: settingData.max !== undefined ? parseFloat(String(settingData.max)) : undefined,
                options: Array.isArray(settingData.options) ? settingData.options : undefined,
              };
            } catch (error) {
              console.error(`Error parsing setting ${settingName}:`, error);
            }
            return acc;
          }, {} as Record<string, Setting>),
          pages: Array.isArray(data.pages) ? data.pages : [],
          keybind: typeof data.keybind === 'number' ? data.keybind : -1,
        };
      } catch (error) {
        console.error(`Error parsing module ${name}:`, error);
        return null;
      }
    }).filter((module): module is Module => module !== null);
  } catch (error) {
    console.error('Error parsing module data:', error);
    console.error('Raw data:', data);
    return [];
  }
}

function parseSettingValue(settingType: string | undefined, value: any): string | number | boolean {
  if (value === null || value === undefined) {
    return '';
  }

  switch (settingType) {
    case 'Boolean':
      return typeof value === 'boolean' ? value : String(value).toLowerCase() === 'true';
    case 'Float':
      return typeof value === 'number' ? value : parseFloat(String(value));
    case 'Integer':
      return typeof value === 'number' ? Math.round(value) : parseInt(String(value), 10);
    default:
      return String(value);
  }
}