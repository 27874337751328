import React from 'react';
import { motion } from 'framer-motion';
import { Download, Check, AlertTriangle } from 'lucide-react';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import axios from 'axios';

const xorDecrypt = (data: Uint8Array, hexKey: string): Uint8Array => {
  const key = new Uint8Array(hexKey.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
  return new Uint8Array(data.map((byte, i) => byte ^ key[i % key.length]));
};

export const DownloadPanel: React.FC = () => {
  const currentVersion = "v1.48 (61927c1)";
  const XOR_KEY = '4aeb1817374826d67149d041303557eddf9d6787c02bf058d8c8910c0a894d05';

  const generateRandomFileName = (extension: string) => {
    const randomString = Math.random().toString(36).substring(2, 10);
    return `${randomString}.${extension}`;
  };

  const handleDownload = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found. Please log in.');
      return;
    }

    try {
      const response = await axios.get('https://api.wax.rest/api/file/x.dll', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });

      const encryptedData = new Uint8Array(response.data);
      const decryptedData = xorDecrypt(encryptedData, XOR_KEY);

      const blob = new Blob([decryptedData], { type: 'application/x-msdownload' });
      const url = window.URL.createObjectURL(blob);

      const randomFileName = generateRandomFileName('dll');
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', randomFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-6 max-w-2xl mx-auto"
    >
      <Card className="bg-black/50 border-yellow-400/30 overflow-hidden">
        <CardHeader className="bg-yellow-400/10 border-b border-yellow-400/30">
          <CardTitle className="text-2xl font-bold text-yellow-400">Download wax.rest</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6 p-6">
          <div className="flex items-center justify-between">
            <span className="text-gray-300">Current Version:</span>
            <span className="text-yellow-400 font-semibold">{currentVersion}</span>
          </div>
          
          <Button 
            className="w-full bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-300"
            onClick={handleDownload}
          >
            <Download className="mr-2 h-5 w-5" />
            Download Latest Version
          </Button>

          <div className="space-y-2 text-sm text-gray-400">
            <div className="flex items-center">
              <Check className="h-4 w-4 text-green-500 mr-2 flex-shrink-0" />
              <span>Windows 10 or Windows 11</span>
            </div>
            <div className="flex items-center">
              <Check className="h-4 w-4 text-green-500 mr-2 flex-shrink-0" />
              <span>Visual C++ Redistributable 2015-2022</span>
            </div>
          </div>

          <div className="bg-yellow-400/5 border border-yellow-400/20 rounded-lg p-4 text-sm text-gray-300">
            <div className="flex items-start">
              <AlertTriangle className="h-5 w-5 text-yellow-400 mr-2 flex-shrink-0 mt-0.5" />
              <p>
                By downloading, you agree to our Terms of Service and Privacy Policy. 
                Make sure you meet the system requirements before installing.
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
};