import React from 'react';
import { motion } from 'framer-motion';
import { Wifi, WifiOff, Menu, User } from 'lucide-react';
import { Button } from './ui/button';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';

interface NavbarProps {
  connectionStatus: 'connecting' | 'connected' | 'disconnected' | 'error';
  toggleSidebar: () => void;
  userProfile: {
    username: string;
    displayname?: string;
  } | null;
}

const WaxIcon: React.FC = () => (
  <motion.svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    animate={{
      filter: ["drop-shadow(0 0 8px #facc15)", "drop-shadow(0 0 16px #facc15)", "drop-shadow(0 0 8px #facc15)"],
    }}
    transition={{
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    }}
  >
    <path d="M16 2L4 8L16 14L28 8L16 2Z" stroke="#facc15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 16L16 22L28 16" stroke="#facc15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 24L16 30L28 24" stroke="#facc15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </motion.svg>
);

export const Navbar: React.FC<NavbarProps> = ({ connectionStatus, toggleSidebar, userProfile }) => {
  return (
    <motion.nav
      className="fixed top-0 left-0 right-0 bg-black/50 backdrop-blur-md border-b border-yellow-400/20 p-4 flex justify-between items-center z-50 h-16"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center space-x-4">
        <Button variant="ghost" size="sm" onClick={toggleSidebar} className="lg:hidden">
          <Menu className="h-6 w-6 text-yellow-400" />
        </Button>
        <div className="flex items-center space-x-2">
          <WaxIcon />
          <span className="text-xl sm:text-2xl font-bold text-yellow-400">wax.rest</span>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <motion.div
          className="flex items-center space-x-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          {connectionStatus === 'connected' ? (
            <Wifi className="text-green-400 h-5 w-5" />
          ) : (
            <WifiOff className="text-red-400 h-5 w-5" />
          )}
          <span className="text-xs sm:text-sm text-yellow-400">{connectionStatus}</span>
        </motion.div>
        {userProfile && (
          <motion.div
            className="flex items-center space-x-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <Avatar className="h-8 w-8">
              <AvatarFallback>{userProfile.username[0].toUpperCase()}</AvatarFallback>
            </Avatar>
            <span className="text-sm text-yellow-400 hidden sm:inline-block">
              {userProfile.displayname || userProfile.username}
            </span>
          </motion.div>
        )}
      </div>
    </motion.nav>
  );
};
